import {Location} from "./models/Advertisement";
import {US_STATES_MAP} from "./constants";
import {DateTime} from "luxon";

export function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export function isEmpty(value: Array<any> | string | undefined): boolean {
    if (value === undefined) {
        return true;
    }

    return value.length === 0;
}

export function toJSDate(seconds: number | undefined) {
    return seconds ? DateTime.fromSeconds(seconds).plus({days: 1}).toJSDate() : undefined;
}

export function formatLocation(location: Location | undefined) {
    if (location === undefined) {
        return;
    }

    const name = location.name;
    const city = location.city;
    const county = location.county;
    const state = US_STATES_MAP.get(location.state as string);
    const country = location.country;

    if (isEmpty(name) && isEmpty(city) && isEmpty(county) && isEmpty(state) && isEmpty(county)) {
        return "Not Provided";
    }

    let display = "";
    if (name && name.trim() !== "") {
        display = display + name;
        if ((city && city.trim() !== "") || (county && county.trim() !== "")
            || (state && state.trim() !== "") || (country && country.trim() !== "")) {
            display = display + ", "
        }
    }

    if (city && city.trim() !== "") {
        display = display + city;
        if ((county && county.trim() !== "") || (state && state.trim() !== "") || (country && country.trim() !== "")) {
            display = display + ", "
        }
    }

    if (county && county !== "") {
        display = display + county;
        if ((state && state.trim() !== "") || (country && country.trim() !== "")) {
            display = display + ", "
        }
    }

    if (state && state !== "") {
        const name = state.substr(state.indexOf('-') + 1, state.length);
        display = display + name;
        if (country && country.trim() !== "") {
            display = display + ", "
        }
    }

    if (country && country !== "") {
        display = display + country;
    }

    return display;
}

export function downloadBlob(blob: any, filename?: string) {
    const file = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    document.body.appendChild(a);

    // @ts-ignore
    a.style.cssText = "display: none";
    a.href = file;
    a.download = filename ? filename : "FOTM-Download.zip";
    a.click();

    window.URL.revokeObjectURL(file);
    document.body.removeChild(a);
}


