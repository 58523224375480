import {QueryClient, useQuery} from "@tanstack/react-query";
import {atomWithImmer, withImmer} from "jotai-immer";
import {atomWithStorage} from "jotai/utils";
import {DEFAULT_COLLECTION_DIALOG, DEFAULT_GUEST, DEFAULT_SEARCH_OPTIONS} from "../../../constants";
import {CollectionDialogData} from "../../../models/CollectionDialogData";
import {useAtom} from "jotai/index";
import {useEffect} from "react";
import {loadCollectedAds, loadCollections, loadUser} from "../../../api";
import {Collection} from "../../../models/Collection";
import {User} from "../../../models/User";

export const QUERY_CLIENT = new QueryClient();

export const searchOptionsAtom = withImmer(atomWithStorage('searchOptions', DEFAULT_SEARCH_OPTIONS));

export const userAtom = atomWithImmer(DEFAULT_GUEST);
export const profileLoadedAtom = atomWithImmer(false);

export const collectionsAtom = atomWithImmer(Array<Collection>());
export const collectedAdsAtom = atomWithImmer(Array<string>());

export const collectionDialogAtom = atomWithImmer<CollectionDialogData>(DEFAULT_COLLECTION_DIALOG);

export const showCollectionDialogAtom = atomWithImmer(false);

export const showMobileSidebarAtom = atomWithImmer(false);

export const loadCollectionsHook = (user: User): any => {
    return useQuery({
        refetchOnReconnect: "always",
        refetchOnWindowFocus: "always",
        refetchOnMount: "always",
        queryKey: ['collectionsSidebar'],
        queryFn: loadCollections,
        placeholderData: [],
        retry: 0,
        enabled: !!user?.id
    });
}

export function Storage() {

    const [user, setUser] = useAtom(userAtom);
    const [, setProfileLoaded] = useAtom(profileLoadedAtom);

    const [, setCollections] = useAtom(collectionsAtom);
    const [, setCollectedAds] = useAtom(collectedAdsAtom);

    const [collectionDialog] = useAtom(collectionDialogAtom);

    const {data: profile, isSuccess: isProfileLoaded} = useQuery({
        refetchOnReconnect: "always",
        refetchOnWindowFocus: "always",
        refetchOnMount: "always",
        refetchInterval: 3 * 60 * 1000,
        queryKey: ['user'],
        queryFn: loadUser,
        placeholderData: DEFAULT_GUEST,
        retry: 0,
    });

    const {data: collectedAds, isSuccess: collectedAdsSuccess, refetch: refetchCollectedAds} = useQuery({
        refetchOnReconnect: "always",
        refetchOnWindowFocus: "always",
        refetchOnMount: "always",
        queryKey: ['collectedAds'],
        queryFn: loadCollectedAds,
        placeholderData: [],
        retry: 0,
        enabled: !!user?.id
    });

    const {data: collections, isSuccess: collectionsLoadedSuccess, refetch: refetchCollections} = useQuery({
        refetchOnReconnect: "always",
        refetchOnWindowFocus: "always",
        refetchOnMount: "always",
        queryKey: ['collections'],
        queryFn: loadCollections,
        placeholderData: [],
        retry: 0,
        enabled: !!user?.id
    });

    useEffect(() => {
        setUser(profile || DEFAULT_GUEST);
    }, [profile]);

    useEffect(() => {
        if (isProfileLoaded) {
            setProfileLoaded(true);
        }
    }, [isProfileLoaded]);

    useEffect(() => {
        setCollectedAds(!collectedAdsSuccess ? [] : collectedAds);
    }, [collectedAds, collectedAdsSuccess]);

    useEffect(() => {
        setCollections(!collectionsLoadedSuccess ? [] : collections);
    }, [collections, collectionsLoadedSuccess]);

    useEffect(() => {
        if (collectionDialog.open) {
            refetchCollections();
        }
        refetchCollectedAds();
    }, [collectionDialog.open]);

    return (
        <></>
    )
}