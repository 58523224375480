import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';

import reportWebVitals from './reportWebVitals';
import {enableMapSet} from "immer";

const container = document.getElementById('root');
const root = createRoot(container!);

const app =
    <StrictMode>
        <App/>
    </StrictMode>

root.render(app);

enableMapSet();
reportWebVitals();
