import {Link} from "react-router-dom";
import {ADS_S3_URL, PATHS} from "../../constants";
import {formatLocation} from "../../utilities";
import {EyeIcon} from "@heroicons/react/24/outline";
import {Advertisement} from "../../models/Advertisement";

export function CollectionItem(advertisement: Advertisement) {

    return (
        <li className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white shadow">
            <div className="flex flex-1 flex-col p-4">
                {advertisement.digitalObject?.filename &&
                    <Link to={PATHS.SINGLE_ADVERTISEMENT.replace(":id", advertisement.id)}>
                        <img src={ADS_S3_URL + advertisement.digitalObject.filename}
                             className="h-64 w-full object-cover"
                             alt={advertisement?.transcription}/>
                    </Link>}

                {!advertisement.digitalObject?.filename &&
                    <p className="h-64 w-full">{advertisement?.transcription?.substring(0, 400)}{advertisement?.transcription && advertisement?.transcription?.length > 400 && "..."}</p>
                }

                <h3 className="mt-6 text-xl font-semibold text-gray-900">{advertisement?.newspaper?.name}</h3>
                <dl className="mt-1 flex flex-grow flex-col">
                    <dt className="sr-only">Publication City</dt>
                    <dd className="text-md font-medium text-gray-800">
                        {formatLocation({
                            city: advertisement?.newspaper?.location?.city,
                            state: advertisement?.newspaper?.location?.state,
                            country: advertisement?.newspaper?.location?.country
                        })}
                    </dd>
                    <dt className="sr-only">Publication Date</dt>
                    <dd className="text-md text-gray-800">
                        <time dateTime={advertisement?.publicationDate}>{advertisement?.publicationDate}</time>
                    </dd>

                    <dt className="sr-only">Labels</dt>
                    <dd className="mt-3">
                        <p className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200 capitalize mr-2">
                            <svg className="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
                                <circle cx={3} cy={3} r={3}/>
                            </svg>
                            {advertisement.type !== undefined && advertisement.type !== 'NOT_PROVIDED' ? advertisement.type?.toLowerCase() + " Ad" : "Unknown Ad Type"}
                        </p>

                        {!advertisement.digitalObject?.filename &&
                            <p className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200 capitalize">
                                <svg className="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
                                    <circle cx={3} cy={3} r={3}/>
                                </svg>
                                No Source Image
                            </p>}

                        {(advertisement?.transcription?.length === 0) &&
                            <p className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200 capitalize">
                                <svg className="h-1.5 w-1.5 fill-orange-600" viewBox="0 0 6 6" aria-hidden="true">
                                    <circle cx={3} cy={3} r={3}/>
                                </svg>
                                Not Transcribed
                            </p>}
                    </dd>
                </dl>
            </div>
            <div>
                <div className="-mt-px flex divide-x divide-gray-200 z-0">
                    <div className="flex w-0 flex-1">
                        <Link to={PATHS.SINGLE_ADVERTISEMENT.replace(":id", advertisement.id)}
                              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                            <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                            Open Advertisement
                        </Link>
                    </div>
                </div>
            </div>
        </li>
    )
}