import {useParams} from "react-router";
import {EyeIcon, UserCircleIcon, FolderOpenIcon, ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import PencilIcon from "@heroicons/react/20/solid/PencilIcon";
import {useQuery} from "@tanstack/react-query";
import {downloadCollection, downloadCollectionFlat, loadCollection} from "../../../api";
import {EMPTY_COLLECTION, NULL_UUID} from "../../../constants";
import {CollectionItem} from "../../tags/CollectionItem";
import {CheckCircleIcon, ShareIcon} from "@heroicons/react/24/solid";
import {useState} from "react";
import {useAtom} from "jotai/index";
import {showCollectionDialogAtom, userAtom} from "../Application/Storage";
import {downloadBlob} from "../../../utilities";
import CollectionSettingsDialog from "../../tags/CollectionSettingsDialog";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";

export default function SingleCollection() {

    const {id} = useParams();

    const [user] = useAtom(userAtom);

    const [, setNewCollectionDialogStore] = useAtom(showCollectionDialogAtom);

    const {data: collection, isSuccess: collectionSuccess, isPending, refetch: refetchCollection} = useQuery({
        refetchOnReconnect: "always",
        refetchOnWindowFocus: "always",
        refetchOnMount: "always",
        queryKey: [id],
        queryFn: () => loadCollection(id || NULL_UUID),
        placeholderData: EMPTY_COLLECTION,
        retry: 0,
    });

    const [copyLinkNotice, setCopyLinkNotice] = useState(false);

    async function copyShareLink(uuid: string | undefined) {
        try {
            const link = uuid !== undefined ? `https://fotm.link/c/${uuid}` : "https://freedomonthemove.org";
            await navigator.clipboard.writeText(link);
            setCopyLinkNotice(true);
            setTimeout(() => setCopyLinkNotice(false), 3000);
        } catch (e) {
            // no-op
        }
    }

    return (
        <>
            {!isPending && <>
                {collectionSuccess &&
                    <div className="container mx-auto">
                        <div
                            className="sticky top-0 flex items-center justify-between bg-white mb-6 px-6 py-12 z-10 border-b-2 border-indigo-600">
                            <div className="min-w-0 flex-1">
                                <h3 className="text-3xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight">
                                    {collection.name}
                                </h3>
                                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                                    <div className="mt-2 flex items-center text-sm text-gray-700 font-semibold">
                                        <UserCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-700"
                                                        aria-hidden="true"/>
                                        {collection?.curator || user?.fullname}
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-gray-700 font-semibold">
                                        <EyeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-700"
                                                 aria-hidden="true"/>
                                        {collection.public ? "Public" : "Private"}
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-gray-700 font-semibold">
                                        <FolderOpenIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-700"
                                                        aria-hidden="true"/>
                                        {(collection?.advertisements?.length || "No") + " Advertisements"}
                                    </div>
                                </div>
                                <pre
                                    className="mt-6 text-lg font-medium text-gray-900 font-leading">{collection.description}</pre>
                            </div>
                            <div className="mt-5 flex lg:ml-4 lg:mt-0">

                                {user?.id === collection.curatorId &&
                                    <div className="hidden sm:block">
                                        <button onClick={() => {
                                            setNewCollectionDialogStore(true);
                                        }}
                                                className="inline-flex items-center rounded-md bg-blue-600 px-4 py-3 ml-3 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                            <PencilIcon className="-ml-0.5 mr-1.5 h-4 w-4 text-white"
                                                        aria-hidden="true"/>
                                            Edit Collection
                                        </button>
                                    </div>
                                }

                                {collection.public && <>
                                    {!copyLinkNotice ?
                                        <div className="hidden sm:block">
                                            <button onClick={() => copyShareLink(collection.id)}
                                                    className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-3 ml-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                <ShareIcon className="h-5 w-5 mr-2 text-white" aria-hidden="true"/>
                                                Share Collection
                                            </button>
                                        </div>
                                        :
                                        <div className="hidden sm:block">
                                            <button disabled
                                                    className="inline-flex items-center rounded-md bg-green-600 px-4 py-3 ml-3 text-md font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                                                <CheckCircleIcon className="h-5 w-5 mr-2 text-white"
                                                                 aria-hidden="true"/>
                                                Copied To Clipboard
                                            </button>
                                        </div>
                                    }
                                </>}

                                {collection?.advertisements?.length > 0 &&
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <MenuButton
                                                className="inline-flex items-center rounded-md bg-teal-600 px-4 py-3 ml-3 text-md font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
                                                <ArrowDownTrayIcon className="-ml-0.5 mr-1.5 h-4 w-4 text-white"
                                                                   aria-hidden="true"/>
                                                Download Options
                                            </MenuButton>
                                        </div>

                                        <MenuItems
                                            className="absolute right-0 z-10 mt-2 w-96 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                        >
                                            <div className="px-4 py-3">
                                                <p className="text-md">Available Formats</p>
                                            </div>
                                            <div className="py-1">
                                                <MenuItem>
                                                    <button className="block px-4 py-2 text-md text-black data-[focus]:bg-gray-100 data-[focus]:text-gray-900 w-full text-left" onClick={async () => {
                                                        await downloadCollection(id || "")
                                                            .then(res => res.blob())
                                                            .then(blob => downloadBlob(blob, "FOTM-Download-Collection-"
                                                                .concat(collection.name.replaceAll(" ", "-"))
                                                                .concat(".zip")));
                                                    }}>
                                                        Full Dataset
                                                    </button>
                                                </MenuItem>
                                                <MenuItem>
                                                    <button
                                                        className="block px-4 py-2 text-md text-black data-[focus]:bg-gray-100 data-[focus]:text-gray-900 w-full text-left"
                                                        onClick={async () => {
                                                            await downloadCollectionFlat(id || "")
                                                                .then(res => res.blob())
                                                                .then(blob => downloadBlob(blob, "FOTM-Download-Collection-"
                                                                    .concat(collection.name.replaceAll(" ", "-"))
                                                                    .concat(".csv")));
                                                        }}>
                                                        Curated Freedom Seeker Centric Flat File
                                                    </button>
                                                </MenuItem>
                                            </div>
                                        </MenuItems>
                                    </Menu>
                                }

                            </div>
                        </div>

                        {collectionSuccess && collection?.advertisements?.length === 0 &&
                            <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
                                <div className="text-center">
                                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">This
                                        Collection is Empty</h1>
                                    <p className="mt-6 text-xl font-semibold text-gray-900">You can add advertisements
                                        to
                                        this
                                        collection from the database search page<br/>or an individual advertisement
                                        metadata
                                        page.</p>
                                </div>
                            </main>
                        }

                        {collectionSuccess && collection?.advertisements?.length > 0 &&
                            <ul
                                className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 px-6 mb-6">
                                {collection.advertisements.map((advertisement) =>
                                    <CollectionItem key={advertisement.id} {...advertisement} />
                                )}
                            </ul>
                        }
                    </div>
                }
            </>}
            <CollectionSettingsDialog collection={collection} refetchCollection={refetchCollection}/>
        </>
    );

}