import {SearchOptions} from "./models/SearchOptions";
import {instantMeiliSearch} from "@meilisearch/instant-meilisearch";
import {history} from "instantsearch.js/es/lib/routers";
import {User} from "./models/User";
import {CollectionDialogData} from "./models/CollectionDialogData";
import {Collection} from "./models/Collection";

export const WEBSITE_NAME = "Freedom on the Move | Cornell University";

export const API_HOST = import.meta.env.VITE_API_HOST ? import.meta.env.VITE_API_HOST : 'http://localhost:8080';

export const MEILISEARCH_HOST = import.meta.env.VITE_MEILISEARCH_HOST ? import.meta.env.VITE_MEILISEARCH_HOST : "http://localhost:7700";
export const MEILISEARCH_KEY = import.meta.env.VITE_MEILISEARCH_API_KEY ? import.meta.env.VITE_MEILISEARCH_API_KEY : "bb522840b3a66002d3336b0d2c9e40f2992926bd0e4b75edc61c1b73ac5838ba";
export const MEILISEARCH_INDEX = "advertisements";

export const CROWDSOURCING_URL = "https://crowdsourcing.freedomonthemove.org/crowdsourcing/";
export const ADS_S3_URL = "https://assets.freedomonthemove.org/";

export const NULL_UUID = "00000000-0000-0000-0000-000000000000";

export enum PATHS {
    SEARCH_ROUTE = "*",
    SEARCH = "/",
    LOGIN = "/login",
    SINGLE_ADVERTISEMENT = "/advertisements/:id",
    COLLECTIONS = "/collections",
    SINGLE_COLLECTION = "/collections/:id",
    DOWNLOADS = "/downloads",
}

export const API_ENDPOINTS = Object.freeze({
    SEARCH: API_HOST + "/search",
    SINGLE_ADVERTISEMENT: API_HOST + "/notices/:id",
    SINGLE_ADVERTISEMENT_COLLECTIONS: API_HOST + "/notices/:id/collections",
    COLLECTIONS: API_HOST + "/collections",
    COLLECTED_ADS: API_HOST + "/collections/collected",
    SINGLE_COLLECTION: API_HOST + "/collections/:id",
    STATS: API_HOST + "/stats",
    LOGIN: API_HOST + "/login",
    LOGOUT: API_HOST + "/logout",
    PROFILE: API_HOST + "/profile",
    DOWNLOAD_SEARCH: API_HOST + "/downloads/search",
    DOWNLOAD_SEARCH_FLAT: API_HOST + "/downloads/search/flat",
    DOWNLOAD_COLLECTION: API_HOST + "/downloads/collections/:id",
    DOWNLOAD_COLLECTION_FLAT: API_HOST + "/downloads/collections/:id/flat",
    DOWNLOAD_DEFAULT_DATASET: API_HOST + "/datasets/full",
    DOWNLOAD_DEFAULT_DATASET_FLATTENED: API_HOST + "/datasets/full/flat"
});

export const MAPPING = Object.freeze({
    BASE_MAP_TILES: (z: number, x: number, y: number) => `https://tiles.freedomonthemove.org/NaturalEarth/${z}/${x}/${y}.png`,
    HISTORICAL_BOUNDARIES: "https://assets.freedomonthemove.org/kmz/us.kml",
    POWERED_BY: '',
    ATTRIBUTION: 'Basemap: <a href="https://www.naturalearthdata.com/downloads/10m-raster-data/10m-natural-earth-2/" target="_blank" rel="noopener noreferrer nofollow">Natural Earth</a> | Historical Boundaries: <a href="https://digital.newberry.org/ahcb/index.html" target="_blank" rel="noopener noreferrer nofollow">Atlas of Historical County Boundaries</a>'
});

export const DEFAULT_PAGE_SIZE = 21;

export const SELECT_BOX_OPTION_LIMIT = 1000;

export const DEFAULT_SEARCH_OPTIONS: SearchOptions = {
    hideImages: false,
    highlightTranscripts: false,
    newTab: false,

    optionsOpened: true,
    newspapersOpened: false,
    advertisementsOpened: false,
    enslavedPersonOpened: false,

    sortBy: {label: 'Select a Sorting Method', value: 'advertisements'}
};

export const DEFAULT_GUEST: User = {
    background: "",
    email: "",
    fullname: "",
    id: "",
    is_admin: false,
    is_mod: false,
    public: false,
    receive_emails: false,
    reputation: 0
}

export const DEFAULT_COLLECTION_DIALOG: CollectionDialogData = {
    advertisementID: NULL_UUID,
    open: false
}

export const EMPTY_COLLECTION: Collection = {
    advertisements: [],
    curator: "",
    curatorId: NULL_UUID,
    description: "",
    id: NULL_UUID,
    name: "",
    public: false
}

export const {searchClient} = instantMeiliSearch(
    MEILISEARCH_HOST,
    MEILISEARCH_KEY,
    {
        primaryKey: 'id',
        finitePagination: true,
    }
);

export const SEARCH_ROUTING = {
    router: history({
        cleanUrlOnDispose: true
    }),
};

export const SORT_BY_ITEMS = [
    {label: 'Publication Date (asc)', value: 'advertisements:publicationDateEpoch:asc'},
    {label: 'Publication Date (desc)', value: 'advertisements:publicationDateEpoch:desc'},
    {label: 'Transcription Length (asc)', value: 'advertisements:transcriptionLength:asc'},
    {label: 'Transcription Length (desc)', value: 'advertisements:transcriptionLength:desc'},
]

export const US_STATES_MAP = new Map(
    [
        ["US-AL", "Alabama"],
        ["US-AK", "Alaska"],
        ["US-AZ", "Arizona"],
        ["US-AR", "Arkansas"],
        ["US-CA", "California"],
        ["US-CO", "Colorado"],
        ["US-CT", "Connecticut"],
        ["US-DC", "District Of Columbia"],
        ["US-DE", "Delaware"],
        ["US-FL", "Florida"],
        ["US-GA", "Georgia"],
        ["US-HI", "Hawaii"],
        ["US-ID", "Idaho"],
        ["US-IL", "Illinois"],
        ["US-IN", "Indiana"],
        ["US-IA", "Iowa"],
        ["US-KS", "Kansas"],
        ["US-KY", "Kentucky"],
        ["US-LA", "Louisiana"],
        ["US-ME", "Maine"],
        ["US-MD", "Maryland"],
        ["US-MA", "Massachusetts"],
        ["US-MI", "Michigan"],
        ["US-MN", "Minnesota"],
        ["US-MS", "Mississippi"],
        ["US-MO", "Missouri"],
        ["US-MT", "Montana"],
        ["US-NE", "Nebraska"],
        ["US-NV", "Nevada"],
        ["US-NH", "New Hampshire"],
        ["US-NJ", "New Jersey"],
        ["US-NM", "New Mexico"],
        ["US-NY", "New York"],
        ["US-NC", "North Carolina"],
        ["US-ND", "North Dakota"],
        ["US-OH", "Ohio"],
        ["US-OK", "Oklahoma"],
        ["US-OR", "Oregon"],
        ["US-PA", "Pennsylvania"],
        ["US-RI", "Rhode Island"],
        ["US-SC", "South Carolina"],
        ["US-SD", "South Dakota"],
        ["US-TN", "Tennessee"],
        ["US-TX", "Texas"],
        ["US-UT", "Utah"],
        ["US-VT", "Vermont"],
        ["US-VA", "Virginia"],
        ["US-WA", "Washington"],
        ["US-WV", "West Virginia"],
        ["US-WI", "Wisconsin"],
        ["US-WY", "Wyoming"]
    ]
);