import {API_ENDPOINTS, DEFAULT_GUEST} from "./constants";
import {User} from "./models/User";
import wretch from "wretch"
import {Collection} from "./models/Collection";
import {SearchFilterState} from "./models/SearchFilterState";

export const authenticate = async (email: string, password: string): Promise<boolean> => {
    const res = await fetch(API_ENDPOINTS.LOGIN, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username: email,
            password: password
        }),
        referrerPolicy: 'no-referrer',
    });

    return res.status === 200;
}

export const logout = async (): Promise<boolean> => {
    const res = await fetch(API_ENDPOINTS.LOGOUT, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        referrerPolicy: 'no-referrer',
    });

    return res.status === 200;
}

export const loadUser = async (): Promise<User> => {
    return await wretch(API_ENDPOINTS.PROFILE)
        .options({credentials: "include", mode: "cors"})
        .get()
        .unauthorized(() => DEFAULT_GUEST)
        .json();
}

export const loadCollections = async (): Promise<Array<Collection>> => {
    return await wretch(API_ENDPOINTS.COLLECTIONS)
        .options({credentials: "include", mode: "cors"})
        .get()
        .json();
}

export const loadCollection = async (id: string): Promise<Collection> => {
    return await wretch(API_ENDPOINTS.SINGLE_COLLECTION.replace(":id", id))
        .options({credentials: "include", mode: "cors"})
        .get()
        .json();
}

export const createCollection = async (newCollection: Collection): Promise<Collection> => {
    return await wretch(API_ENDPOINTS.COLLECTIONS)
        .options({credentials: "include", mode: "cors"})
        .post(newCollection)
        .json();
}

export const updateCollection = async (collection: Collection): Promise<Collection> => {
    collection.advertisements = [];

    return await wretch(API_ENDPOINTS.SINGLE_COLLECTION.replace(":id", collection.id))
        .options({credentials: "include", mode: "cors"})
        .put(collection)
        .json();
}

export const loadCollectionsForAdvertisement = async (id: string): Promise<Array<string>> => {
    return await wretch(API_ENDPOINTS.SINGLE_ADVERTISEMENT_COLLECTIONS.replace(":id", id))
        .options({credentials: "include", mode: "cors"})
        .get()
        .json();
}

export const updateCollectionsForAdvertisement = async (id: string, collectionIDs: Array<string>): Promise<Array<string>> => {
    return await wretch(API_ENDPOINTS.SINGLE_ADVERTISEMENT_COLLECTIONS.replace(":id", id))
        .options({credentials: "include", mode: "cors"})
        .put(collectionIDs)
        .res();
}

export const loadCollectedAds = async (): Promise<Array<string>> => {
    return await wretch(API_ENDPOINTS.COLLECTED_ADS)
        .options({credentials: "include", mode: "cors"})
        .get()
        .json();
}

export const downloadSearchResults = async (filters: SearchFilterState): Promise<any> => {
    return await wretch(API_ENDPOINTS.DOWNLOAD_SEARCH)
        .options({credentials: "include", mode: "cors"})
        .post(filters)
        .res();
}

export const downloadSearchResultsFlat = async (filters: SearchFilterState): Promise<any> => {
    return await wretch(API_ENDPOINTS.DOWNLOAD_SEARCH_FLAT)
        .options({credentials: "include", mode: "cors"})
        .post(filters)
        .res();
}

export const downloadCollection = async (id: string): Promise<any> => {
    return await wretch(API_ENDPOINTS.DOWNLOAD_COLLECTION.replace(":id", id))
        .options({credentials: "include", mode: "cors"})
        .get()
        .res();
}

export const downloadCollectionFlat = async (id: string): Promise<any> => {
    return await wretch(API_ENDPOINTS.DOWNLOAD_COLLECTION_FLAT.replace(":id", id))
        .options({credentials: "include", mode: "cors"})
        .get()
        .res();
}
