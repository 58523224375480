import {lazy, Suspense} from 'react';
import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider} from "react-router-dom";
import {DEFAULT_PAGE_SIZE, MEILISEARCH_INDEX, PATHS, SEARCH_ROUTING, searchClient, WEBSITE_NAME} from "./constants";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Configure, InstantSearch} from "react-instantsearch";
import {QueryClientProvider} from "@tanstack/react-query";
import {QUERY_CLIENT, Storage, userAtom} from "./components/pages/Application/Storage";
import './css/search.css';
import SingleCollection from "./components/pages/Collections/SingleCollection";
import {useAtom} from "jotai/index";

export default function App() {

    const [user] = useAtom(userAtom);

    const ApplicationShell = lazy(() => import("./components/pages/Application/ApplicationShell"));
    const Login = lazy(() => import("./components/pages/Login/Login"));
    const Search = lazy(() => import("./components/pages/Search/Search"));
    const Collections = lazy(() => import("./components/pages/Collections/Collections"));
    const Downloads = lazy(() => import("./components/pages/Downloads/Downloads"));
    const DetailedView = lazy(() => import("./components/pages/DetailedView/DetailedView"));

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path={PATHS.SEARCH_ROUTE} element={<>
                    <Helmet>
                        <title>{"Database - " + WEBSITE_NAME}</title>
                    </Helmet>

                    <Suspense>
                        <ApplicationShell>
                            <Search/>
                        </ApplicationShell>
                    </Suspense>
                </>}/>

                <Route path={PATHS.COLLECTIONS} element={
                    !user?.id ? <Navigate to={PATHS.LOGIN}/> :
                        <>
                            <Helmet>
                                <title>{"Your Collections - " + WEBSITE_NAME}</title>
                            </Helmet>

                            <Suspense>
                                <ApplicationShell>
                                    <Collections/>
                                </ApplicationShell>
                            </Suspense>
                        </>
                }/>

                <Route path={PATHS.DOWNLOADS}
                       element={
                           <>
                               <Helmet>
                                   <title>{"Downloads - " + WEBSITE_NAME}</title>
                               </Helmet>

                               <Suspense>
                                   <ApplicationShell>
                                       <Downloads/>
                                   </ApplicationShell>
                               </Suspense>
                           </>
                       }/>

                <Route path={PATHS.SINGLE_COLLECTION}
                       element={
                           <Suspense>
                               <ApplicationShell>
                                   <SingleCollection/>
                               </ApplicationShell>
                           </Suspense>
                       }/>

                <Route path={PATHS.SINGLE_ADVERTISEMENT}
                       element={
                           <Suspense>
                               <ApplicationShell>
                                   <DetailedView/>
                               </ApplicationShell>
                           </Suspense>
                       }/>

                <Route path={PATHS.LOGIN} element={
                    user?.id ? <Navigate to={PATHS.SEARCH}/> :
                        <>
                            <Helmet>
                                <title>{"Login - " + WEBSITE_NAME}</title>
                            </Helmet>

                            <Suspense>
                                <Login/>
                            </Suspense>
                        </>
                }/>

            </>
        )
    );

    return (
        <HelmetProvider>
            <InstantSearch indexName={MEILISEARCH_INDEX} searchClient={searchClient} routing={SEARCH_ROUTING} future={{
                preserveSharedStateOnUnmount: true,
            }}>
                <Configure page={1} hitsPerPage={DEFAULT_PAGE_SIZE} attributesToSnippet={['transcription:76']}/>
                <QueryClientProvider client={QUERY_CLIENT}>
                    <Storage/>
                    <RouterProvider router={router}/>
                </QueryClientProvider>
            </InstantSearch>
        </HelmetProvider>
    );
}
