import {Fragment, useEffect, useState} from "react";
import {Dialog, DialogPanel, DialogTitle, Transition, TransitionChild} from "@headlessui/react";
import {useAtom} from "jotai/index";
import {showCollectionDialogAtom} from "../pages/Application/Storage";
import {EMPTY_COLLECTION, PATHS} from "../../constants";
import {createCollection, updateCollection} from "../../api";
import {useNavigate} from "react-router";
import {Collection} from "../../models/Collection";

interface Props {
    collection?: Collection,
    refetchCollection?: Function
}

export default function CollectionSettingsDialog(props: Props) {

    const navigate = useNavigate();

    const [showCollectionDialogStore, setShowCollectionDialogStore] = useAtom(showCollectionDialogAtom);

    const [editCollection, setEditCollection] = useState(EMPTY_COLLECTION)

    const close = () => {
        setShowCollectionDialogStore(false);
    }

    useEffect(() => {
        setEditCollection(props.collection || EMPTY_COLLECTION);
    }, [props.collection]);

    async function create() {
        const created = await createCollection(editCollection);
        const id = created.id;
        navigate(PATHS.SINGLE_COLLECTION.replace(":id", id));
    }

    async function update() {
        await updateCollection(editCollection);
        if (props.refetchCollection) {
            props.refetchCollection();
        }
    }

    return (
        <Transition show={showCollectionDialogStore} as={Fragment}>
            <Dialog className="relative z-10" onClose={close}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <DialogTitle as="h3"
                                                     className="text-2xl font-semibold leading-6 text-gray-900">
                                            {props.collection ? "Update " : "New "} Collection
                                        </DialogTitle>
                                        <div className="mt-8 text-left">
                                            <div className="sm:col-span-4">
                                                <label htmlFor="name"
                                                       className="block text-md font-medium leading-6 text-gray-900">
                                                    Name
                                                </label>
                                                <div className="mt-2">
                                                    <div
                                                        className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            value={editCollection.name}
                                                            onChange={(e) => setEditCollection({
                                                                ...editCollection,
                                                                name: e.target.value
                                                            })}
                                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                            placeholder="Collection Name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-full mt-4">
                                                <label htmlFor="description"
                                                       className="block text-md font-medium leading-6 text-gray-900">
                                                    Description
                                                </label>
                                                <div className="mt-2">
                                                    <textarea
                                                        id="description"
                                                        name="description"
                                                        rows={3}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        value={editCollection.description}
                                                        onChange={(e) => setEditCollection({
                                                            ...editCollection,
                                                            description: e.target.value
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-6 pb-6 space-y-10">
                                                <div className="mt-6 space-y-6">
                                                    <div className="relative flex gap-x-3">
                                                        <div className="flex h-6 items-center">
                                                            <input
                                                                id="public"
                                                                name="public"
                                                                type="checkbox"
                                                                checked={editCollection.public}
                                                                onChange={(e) => setEditCollection({
                                                                    ...editCollection,
                                                                    public: e.target.checked
                                                                })}
                                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                            />
                                                        </div>
                                                        <div className="text-md leading-6">
                                                            <label htmlFor="public"
                                                                   className="font-medium text-gray-900">
                                                                Make Public
                                                            </label>
                                                            <p className="text-gray-500 text-sm">Other users with a link
                                                                to this
                                                                collection can view the name, description and
                                                                contents.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                        onClick={async () => {
                                            if (props.collection) {
                                                await update().then(() => close());
                                            } else {
                                                await create().then(() => close());
                                            }
                                        }}>
                                        {props.collection ? "Update" : "Create"}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={close}>
                                        Cancel
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}